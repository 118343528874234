/* rock-salt-regular - latin */
@font-face {
  font-family: "Rock Salt";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/rock-salt-v11-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Rock Salt Regular"), local("RockSalt-Regular"),
    url("../fonts/rock-salt-v11-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/rock-salt-v11-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/rock-salt-v11-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/rock-salt-v11-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/rock-salt-v11-latin-regular.svg#RockSalt") format("svg"); /* Legacy iOS */
}

/* roboto-300 - latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v20-latin-300.eot"); /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"),
    url("../fonts/roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v20-latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-300italic - latin */
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/roboto-v20-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"),
    url("../fonts/roboto-v20-latin-300italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-300italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-300italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-300italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v20-latin-300italic.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("../fonts/roboto-v20-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v20-latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-500italic - latin */
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/roboto-v20-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"),
    url("../fonts/roboto-v20-latin-500italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-500italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-500italic.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-500italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v20-latin-500italic.svg#Roboto") format("svg"); /* Legacy iOS */
}
