header.masthead {
  min-height: 30rem;
  position: relative;
  display: table;
  width: 100%;
  height: auto;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background: linear-gradient(90deg, fade-out($white, 0.9) 0%, fade-out($white, 0.9) 100%), url("../img/bg_landing.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .container {
    width: 100%;
    max-width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0%) 0%,
      rgba(255, 255, 255, 50%) 15% 85%,
      rgba(255, 255, 255, 0%) 100%
    );
  }

  h1 {
    font-size: 4rem;
    margin: 0;
    margin-bottom: 1rem !important;
    padding: 0;
    font-family: $thfont;
  }

  a {
    font-size: 3.5em;

    &:hover {
      text-decoration: none;
    }
  }

  @media (min-width: 992px) {
    height: 100vh;

    h1 {
      font-size: 5.5rem;
    }
  }
}
