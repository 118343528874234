body,
html {
  width: 100%;
  height: 100%;
}

body {
  @include body-font;
}

/* Custom Button Size */
.btn-xl {
  padding: 1.25rem 2.5rem;
}

/* Content Section */
.content-section {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.content-section-heading {
  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 1rem;
    text-transform: uppercase;
  }
}

/* Typography */

.text-faded {
  color: fade-out($white, 0.3);
}

/* Scroll to top button */
.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 999999;
  display: block;
  width: 50px;
  height: 50px;
  font-size: 2.5em;
  opacity: 0%;
  transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s;
  transform: scale(0);

  &.show {
    opacity: 100%;
    transform: scale(1);
  }

  i {
    background: $primary;
    border-radius: 50%;
    padding: 4px;
    width: 50px;
    height: 50px;
    display: block;
  }

  .bi::before {
    font-size: 42px;
    vertical-align: top;
  }

  &:not(:hover) i {
    color: $white;
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }
}
