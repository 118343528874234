#zimmer .col-lg-6 {
  padding: 0.1em;
}

.room-item {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem;

  img {
    opacity: 85%;
  }

  .caption {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: fade-out($gray-900, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;

    .caption-content {
      color: $white;
      margin: auto 2rem 2rem;

      p {
        font-weight: 300;
        font-size: 1.2rem;
      }
    }
  }

  @media (min-width: 992px) {
    max-width: none;
    margin: 0;

    .caption {
      transition: clip-path 0.25s ease-out, background-color 0.7s;
      clip-path: inset(0);

      .caption-content {
        transition: opacity 0.25s;
        margin-left: 5rem;
        margin-right: 5rem;
        margin-bottom: 5rem;
      }
    }

    img {
      transition: clip-path 0.25s ease-out;
      clip-path: inset(-1px);
    }

    &:hover {
      img {
        clip-path: inset(2rem);
      }

      .caption {
        background-color: fade-out($primary, 0.1);
        clip-path: inset(2rem);
      }
    }
  }
}

section.room-detail {
  .masthead {
    background-position: center;
    background-size: cover;
    min-height: 20rem;
    opacity: $img-opacity;
    height: 100%;
  }
}

@media (max-width: 991px) {
  section.room-detail {
    .text {
      text-align: justify !important;
    }
  }
}

section#eingang {
  .masthead {
    background-image: url("../img/raeume/eingang.jpg");
  }
}

section#wohnzimmer {
  .masthead {
    background-image: url("../img/raeume/wohnzimmer.jpg");
  }
}

section#kueche {
  .masthead {
    background-image: url("../img/raeume/kueche.jpg");
  }
}

section#schlafzimmer {
  .masthead {
    background-image: url("../img/raeume/schlafzimmer.jpg");
  }
}

section#badezimmer {
  .masthead {
    background-image: url("../img/raeume/badezimmer.jpg");
  }
}

section#aussenbereich {
  .masthead {
    background-image: url("../img/raeume/aussen.jpg");
  }
}
