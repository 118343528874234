/* Map */

.map {
  height: 45rem;
  background: url("../img/map.min.svg");
  background-position: top;
  background-size: cover;
  align-items: flex-end;
  display: flex;

  .btn {
    margin: 1rem;
  }

  #ext-map {
    display: none;
  }

  #map-loader {
    display: block;
  }
}

.map.interactive {
  background: none;
  position: relative;

  #osmmap {
    height: 100%;
    width: 100%;
    border: 0;
    position: relative;
    z-index: 1;
  }

  #ext-map {
    display: block;
  }

  #map-loader {
    display: none;
  }

  .btn {
    position: absolute;
    z-index: 2;
  }
}

@media (max-width: 992px) {
  .map {
    height: 75%;
  }
}
