#main-nav {
  min-height: 3.5rem;
  background-color: $white;
  padding: 0 1rem;

  .heightdummy {
    padding: 2rem 1.5rem;
    border-bottom: 0.25rem solid transparent;
  }

  .navbar-toggler {
    font-size: 80%;
    padding: 0.75rem;
    color: $primary;
    border: 2px solid $primary;

    &:focus {
      outline: none;
    }
  }

  .navbar-brand {
    color: $black;
    font-weight: 700;
    padding: 0.9rem 0;
  }

  .navbar-nav {
    .nav-item {
      &:hover {
        color: fade($white, 80%);
        outline: none;
        background-color: transparent;
        transition: color 0.5s ease-in-out;
      }

      &:active,
      &:focus {
        outline: none;
        background-color: transparent;
      }

      .nav-link {
        &.active {
          color: $primary;
        }
      }
    }
  }

  .dropdown-menu {
    border: none;
  }

  @media (max-width: 991px) {
    .submenu {
      .dropdown-menu {
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding-left: 0.75rem;
      }
    }
  }

  @media (min-width: 992px) {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
    background: $menu-background;
    transition: background-color 0.3s ease-in-out;

    &.navbar-expand-lg {
      flex-wrap: wrap;
    }

    .navbar-brand {
      padding: 0.5rem 0;
      color: $foreground;
    }

    .nav-link {
      transition: none;
      padding: 2rem 1.5rem;
      color: $foreground;

      &:hover {
        color: $primary;
      }

      &:active {
        color: $primary;
      }

      &.active {
        color: $primary;
        outline: none;
        border-bottom: 0.25rem solid $primary;
      }
    }

    .submenu {
      position: absolute;
      width: 100%;
      left: 0;
      display: flex;
      z-index: 1;
      background-color: $menu-background;
      transition: background-color ease-in-out 0.3s;

      .dropdown-menu {
        width: 100%;
        left: 0;
        float: left;
        min-width: 160px;
        padding: 5px 0;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        text-align: center;
        position: relative;
        flex-flow: row wrap;
        background-color: $menu-background;

        .nav-item {
          display: inline-block;

          .nav-link {
            padding: 0.5rem 0.5rem 0.25rem;
          }
        }
      }
    }
  }
}
