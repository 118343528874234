a {
  color: $primary;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: darken($primary, 10%);
  }
}

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  color: $white !important;

  &:hover,
  &:focus,
  &:active {
    background-color: darken($primary, 10%) !important;
    border-color: darken($primary, 10%) !important;
  }
}

.btn-secondary {
  background-color: $secondary !important;
  border-color: $secondary !important;
  color: $white !important;

  &:hover,
  &:focus,
  &:active {
    background-color: darken($secondary, 10%) !important;
    border-color: darken($secondary, 10%) !important;
  }
}

.btn-dark {
  color: $white !important;
}

.btn {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 10%);
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding: 15px;
}

.navbar-collapse {
  flex-grow: 0;
}
