$bootstrap-icons-font-src: url("~/node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff2?30af91bf14e37666a085fb8a161ff36d") format("woff2"),
  url("~/node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff?30af91bf14e37666a085fb8a161ff36d") format("woff");

@import "variables";
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons";
@import "mixins";
@import "global";
@import "masthead";
@import "menu";
@import "rooms";
@import "map";
@import "footer";
@import "bootstrap-overrides";
@import "fonts";

section:first-of-type {
  margin-top: 5.75rem;
}

body {
  color: $foreground;
  font-weight: 300;
  scroll-snap-type: none;
  overflow-wrap: break-word;

  & > section:only-of-type {
    min-height: 100vh;
  }
}

#main-nav {
  img {
    height: 3.5rem;
  }
}

.tweete-heimaat {
  font-family: $thfont;
}

.dtvstar {
  height: 0.8em;
  display: inline-block;
  vertical-align: text-top;
}

body:not(.sticked) {
  &.has-submenu {
    @media (max-width: 991px) {
      section:first-of-type {
        margin-top: 0;
      }
    }

    @media (min-width: 992px) {
      section:first-of-type {
        margin-top: 3.125rem;
      }
    }
  }
}

.has-submenu {
  @media (max-width: 991px) {
    section {
      scroll-snap-align: none;
      scroll-margin-top: 90px;
    }

    section:first-of-type {
      margin-top: 5.75rem;
    }
  }

  @media (min-width: 992px) {
    section {
      scroll-snap-align: none;
      scroll-margin-top: 142px;
    }

    section:first-of-type {
      margin-top: 8.875rem;
    }
  }
}

@media (max-width: 991px) {
  #page-top {
    scroll-snap-align: none;
    scroll-margin-top: 90px;
  }
}

@media (min-width: 992px) {
  #page-top {
    scroll-snap-align: none;
    scroll-margin-top: 142px;
  }
}

footer {
  background-color: $primary;
  color: $white;
}
